<script setup>
import "@aws-amplify/ui-vue/styles.css";
import { Authenticator } from "@aws-amplify/ui-vue";
import { Amplify } from 'aws-amplify';
Amplify.configure({
    Auth: {
  
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'arn:aws:cognito-idp:ap-southeast-2:755239865696:userpool/ap-southeast-2_tAAG2PiL9',
  
      // REQUIRED - Amazon Cognito Region
      region: 'ap-southeast-2',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-southeast-2_tAAG2PiL9',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '3sk4rki81ob11gm1tbtmocmora',
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
  
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH'
  }
});
</script>


<template>
<authenticator>
  <section>
    <header>
      <h1>Serial Plate Number With QR Code</h1>
      
    </header>
    <application-form></application-form>
  </section>
  </authenticator>
</template>

<script>
export default {
  data() {
    return {
      drawings:'',
      referrer:'',
      qrurl:'v-123',
      info: '',
      model_no:'',
      serial_no:'',
      tag_no:'',
      built_date:'',
      mass_kg:'',
    };
  },
  
};
</script>

<style>
* {
  box-sizing: border-box;
}
html {
  font-family: "Jost", sans-serif;
}
body {
  margin: 0;
}
header {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 3rem auto;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
  color: #00584f;
  text-align: center;
  width: 90%;
  max-width: 40rem;
}
#app ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#app li {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 1rem auto;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  width: 90%;
  max-width: 40rem;
}
#app h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #58004d;
  margin: 0 0 1rem 0;
}
#app button {
  font: inherit;
  cursor: pointer;
  border: 1px solid #00584f;
  background-color: #00584f;
  color: white;
  padding: 0.05rem 1rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
}
#app button:hover,
#app button:active {
  background-color: #ec3169;
  border-color: #ec3169;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.26);
}
label, input {
  margin-bottom: 0.5rem;
  display: block;
  width: 100%;
}

label {
  font-weight: bold;
}
#app {
  margin: 3rem auto;
  max-width: 80rem;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
</style>