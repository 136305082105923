<template>
  <qrcode-vue :value="qrCode" :size="size" level="H" />
</template>
<script>
  import QrcodeVue from 'qrcode.vue'

  export default {
    props:[
    'qrCode'
  ],
    data() {
      return {
        value: '',
        serial_no: '',
        size: 300,
      }
    },
    components: {
      QrcodeVue,
    },
  }
</script>