import { createApp } from 'vue';


import App from './App.vue';
import ApplicationForm from './components/ApplicationForm.vue';
import AWS from 'aws-sdk';
import { Amplify } from 'aws-amplify';


Amplify.configure({
    Auth: {
  
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'arn:aws:cognito-idp:ap-southeast-2:755239865696:userpool/ap-southeast-2_mHhrqKJYT',
  
      // REQUIRED - Amazon Cognito Region
      region: 'ap-southeast-2',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-southeast-2_mHhrqKJYT',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '3elcqdemmfvbgs0pjv45ke6rot',
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
  
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH'
  }
});


const app = createApp(App);
AWS.config.update( {
    region: 'ap-southeast-2',
    secretAccessKey: 'NNOWeSeJtP0TeXWIjHxFEfX4yZO253bSaeRIahSG',
    accessKeyId: 'AKIA27V6NZVQBZM7UDN7'
  })

app.$docClient = new AWS.DynamoDB.DocumentClient();
app.component('application-form', ApplicationForm);


app.mount('#app');

