<template>
<div id="table09">
  <br>
  <table width=100% height=100% > 
          <tr>
              <td colspan="2" style="text-align: center; vertical-align: middle;">
                <img :src="'SP_CMYK.jpg'" width="750" height="220" >
              </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: center; vertical-align: middle;font-weight: bold;font-size: 18px;">
              S C H E N C K  &nbsp;&nbsp;  P R O C E S S  &nbsp;&nbsp;  A U S T R A L I A  &nbsp;&nbsp;  P T Y .  &nbsp;&nbsp;  L I M I T E D  &nbsp;&nbsp;   A B N  &nbsp;&nbsp;  9 1  0 7 3  &nbsp;&nbsp;  3 1 2  &nbsp;&nbsp;  4 3 7 <br>
            </td>
         </tr>
         <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  M O D E L &nbsp;  N o  : {{modelNumber}}
          </td>
          <td rowspan="7" style="text-align: right;vertical-align: middle;font-weight: bold;font-size: 18px;padding-right: 200px;">
            <qr-code :qr-code="qrCode"></qr-code>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  S E R I A L &nbsp;  N o  : {{serialNumber}}
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  T A G &nbsp;  N o.  :  {{tagNumber}}
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  E Q U I P M E N T &nbsp; N A M E  : {{equipmentName}}
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  C O N T R A C T &nbsp; No. :  {{contractNumber}}
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  B U I L T &nbsp;  D A T E  : {{format_date(builtDate)}}
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  E S T &nbsp;  M A S S &nbsp;  (Kg) : {{massKg}}
          </td>
       </tr>
        <tr>
          <td style="vertical-align: middle;font-weight: bold;font-size: 18px;">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  R E B U I L D &nbsp; J O B &nbsp; No.  :  {{rebuildJob}}
          </td>
        </tr>
       <tr>
        <td colspan="2" style="text-align: center;vertical-align: middle;font-weight: bold;font-size: 18px;">
          <br><br><br>w w w . s c h e n c k p r o c e s s . c o m . a u
        </td>
      </tr>
      </table>
  </div>
  <button @click.prevent="genratePdf(serialNumber,modelNumber,builtDate)"> Download pdf</button>
</template>

<script>
import QrCode from './QrCode.vue';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from 'moment';
import AWS from 'aws-sdk';
export default {
  components: { QrCode },
  props:[
    'name',
    'serialNumber',
    'qrCode',
    'modelNumber',
    'tagNumber',
    'rebuildJob',
    'builtDate',
    'massKg',
    'equipmentName',
    'contractNumber',
  ],
  methods: {
    format_date(value){
         if (value) {
           return moment(String(value)).format('YYYY/MM/DD')
          }
        },
    genratePdf(serialNumber,modelNumber,builtDate) {
      window.html2canvas = html2canvas;
      var doc =new jsPDF("l","pt","a3");
      doc.html(document.querySelector("#table09"),{
        callback: function(pdf){
          pdf.save("pdf.pdf");
        }
      });
      var docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
        TableName: 'qrcode',
        Item: {
          'Id': parseInt(moment().format('YYYYMMDDHHmmss')),
          'serialnumber' : serialNumber,
          'modelnumber' : modelNumber,
          'builtdate' : builtDate,
          'drawingNo':'000-100002-9'
        }
      };
      

      docClient.put(params, function(err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          console.log("Success", data);
        }
      });
    }
  }
  
};
</script>