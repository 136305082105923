<template>
    <form align="center">
        <div>
        <label for="machine" >Select Machine Type</label>
        <select type="text" v-model="enteredMachine">
        <option value="exciter"> Exciter </option>
        <option value="screen"> Screen </option>
        </select>
        </div>
        <div v-if="enteredMachine === 'screen'" >
        <label for="goal" >Select Drawing No.</label>
        <select type="text" v-model="enteredDrwaing" >
        <option value="000-100002-2">000-100002-2</option>
        <option value="000-100002-4">000-100002-4</option>
        <option value="000-100002-6">000-100002-6</option>
        <option value="000-100002-7">000-100002-7</option>
        <option value="000-100002-9">000-100002-9</option>
        <option value="000-100002-12">000-100002-12</option>
        <option value="000-100002-18">000-100002-18</option>
        </select>
        </div>
    </form>
    <form>
    <div v-if="enteredDrwaing === '000-100002-2'" >
        <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
    <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >Tag No:</label>
    <input type="text" v-model="enteredTagno" placeholder="Tag No" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid Tag No!</p>
    </div>
    <div class="form-control" :class="{invalid: equiepmentnameValidity === 'invalid'}">
        <label for="enteredEquipmentname" class="control-label" >Equipement Name:</label>
    <input type="text" v-model="enteredEquipmentname" placeholder="Equipement Name" @blur="validateequiepmentname">
    <p v-if="equiepmentnameValidity === 'invalid'">Please enter a valid Equipement Name!</p>
    </div>
    <div class="form-control" :class="{invalid: contactnoValidity === 'invalid'}">
        <label for="enteredContractno" class="control-label" >Contract No:</label>
    <input type="text" v-model="enteredContractno" placeholder="Contract No" @blur="validatecontactno">
    <p v-if="contactnoValidity === 'invalid'">Please enter a valid Contract No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="yyyy/mm/dd" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <drawing-selection-02
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:equipment-name="enteredEquipmentname"
    v-bind:contract-number="enteredContractno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    ></drawing-selection-02>
    </div>
    <div v-else-if="enteredDrwaing === '000-100002-4'" >
        <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >Tag No:</label>
    <input type="text" v-model="enteredTagno" placeholder="Tag No" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid Tag No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Built Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <drawing-selection-04
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    ></drawing-selection-04>
    </div>
    <div v-else-if="enteredDrwaing === '000-100002-6'" >
        <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >Tag No:</label>
    <input type="text" v-model="enteredTagno" placeholder="Tag No" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid Tag No!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuildserialnoValidity === 'invalid'}">
        <label for="enteredRebuildserialno" class="control-label" >Rebuild Serial No:</label>
    <input type="text" v-model="enteredRebuildserialno" placeholder="Rebuild Serial No" @blur="validaterebuildserialno">
    <p v-if="rebuildserialnoValidity === 'invalid'">Please enter a valid Rebuild Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Built Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuiltdateValidity === 'invalid'}">
        <label for="enteredRebuilddate" class="control-label" >Rebuild Date:</label>
    <input type="date" v-model="enteredRebuilddate" placeholder="Rebuild Date" @blur="validaterebuiltdate">
    <p v-if="rebuiltdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <drawing-selection-06
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:rebuild-serial="enteredRebuildserialno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    v-bind:rebuild-date="enteredRebuilddate"
    ></drawing-selection-06>
    </div>
    <div v-else-if="enteredDrwaing === '000-100002-7'" >
    <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >Tag No:</label>
    <input type="text" v-model="enteredTagno" placeholder="Tag No" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid Tag No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Built Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuildjobnoValidity === 'invalid'}">
        <label for="enteredRebuildjobno" class="control-label" >Rebuild Job No:</label>
    <input type="text" v-model="enteredRebuildjobno" placeholder="Rebuild Job No" @blur="validaterebuildjobno">
    <p v-if="rebuildjobnoValidity === 'invalid'">Please enter a valid Rebuild Job No!</p>
    </div>
    <div class="form-control" :class="{invalid: ranoValidity === 'invalid'}">
        <label for="enteredRano" class="control-label" >RA number:</label>
    <input type="text" v-model="enteredRano" placeholder="RA number" @blur="validaterano">
    <p v-if="ranoValidity === 'invalid'">Please enter a valid  RA number!</p>
    </div>
    <div class="form-control" :class="{invalid: bhpbioValidity === 'invalid'}">
        <label for="enteredBhpbio" class="control-label" >BHPBIO DRG. No:</label>
    <input type="text" v-model="enteredBhpbio" placeholder="BHPBIO DRG. No." @blur="validatebhpbio">
    <p v-if="bhpbioValidity === 'invalid'">Please enter a valid BHPBIO DRG. No.!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuiltdateValidity === 'invalid'}">
        <label for="enteredRebuilddate" class="control-label" >Rebuild Date:</label>
    <input type="date" v-model="enteredRebuilddate" placeholder="Rebuild Date" @blur="validaterebuiltdate">
    <p v-if="rebuiltdateValidity === 'invalid'">Please enter a valid  Rebuild Date!</p>
    </div>
    <div class="form-control" :class="{invalid: bhpstockcodeValidity === 'invalid'}">
        <label for="enteredStockcode" class="control-label" >BHP Stock Code</label>
    <input type="text" v-model="enteredStockcode" placeholder="BHP Stock Code" @blur="validatebhpstockcode">
    <p v-if="bhpstockcodeValidity === 'invalid'">Please enter a valid BHP Stock Code!</p>
    </div>
    <div class="form-control" :class="{invalid: purchaseorderValidity === 'invalid'}">
        <label for="enteredPurchaseorder" class="control-label" >Purchase Order:</label>
    <input type="text" v-model="enteredPurchaseorder" placeholder="Purchase Order" @blur="validatepurchaseorder">
    <p v-if="purchaseorderValidity === 'invalid'">Please enter a valid  Purchase Order!</p>
    </div>
    <drawing-selection-07
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:rebuild-job="enteredRebuildjobno"
    v-bind:ra-no="enteredRano"
    v-bind:bhp-bio="enteredBhpbio"
    v-bind:stock-code="enteredStockcode"
    v-bind:purchase-order="enteredPurchaseorder"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    v-bind:rebuild-date="enteredRebuilddate"
    ></drawing-selection-07>
    </div>
        <div v-else-if="enteredDrwaing === '000-100002-9'" >
    <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >Tag No:</label>
    <input type="text" v-model="enteredTagno" placeholder="Tag No" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid Tag No!</p>
    </div>
    <div class="form-control" :class="{invalid: equiepmentnameValidity === 'invalid'}">
        <label for="enteredEquipmentname" class="control-label" >Equipement Name:</label>
    <input type="text" v-model="enteredEquipmentname" placeholder="Equipement Name" @blur="validateequiepmentname">
    <p v-if="equiepmentnameValidity === 'invalid'">Please enter a valid Equipement Name!</p>
    </div>
    <div class="form-control" :class="{invalid: contactnoValidity === 'invalid'}">
        <label for="enteredContractno" class="control-label" >Contract No:</label>
    <input type="text" v-model="enteredContractno" placeholder="Contract No" @blur="validatecontactno">
    <p v-if="contactnoValidity === 'invalid'">Please enter a valid Contract No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Built Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuildjobnoValidity === 'invalid'}">
        <label for="enteredRebuildjobno" class="control-label" >Rebuild Job No:</label>
    <input type="text" v-model="enteredRebuildjobno" placeholder="Rebuild Job No" @blur="validaterebuildjobno">
    <p v-if="rebuildjobnoValidity === 'invalid'">Please enter a valid Rebuild Job No!</p>
    </div>
    <drawing-selection-09
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    v-bind:equipment-name="enteredEquipmentname"
    v-bind:contract-number="enteredContractno"
    v-bind:rebuild-job="enteredRebuildjobno"
    ></drawing-selection-09>
    </div>
    <div v-else-if="enteredDrwaing === '000-100002-12'" >
    <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Model No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Serial No:</label>
    <input type="text" placeholder="Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: materialnoValidity === 'invalid'}">
        <label for="enteredMaterialno" class="control-label" >Material No:</label>
    <input type="text" v-model="enteredMaterialno" placeholder="Material No" @blur="validatematerialno">
    <p v-if="materialnoValidity === 'invalid'">Please enter a valid Material No!</p>
    </div>
    <div class="form-control" :class="{invalid: equiepmentnameValidity === 'invalid'}">
        <label for="enteredEquipmentname" class="control-label" >Equipement Name:</label>
    <input type="text" v-model="enteredEquipmentname" placeholder="Equipement Name" @blur="validateequiepmentname">
    <p v-if="equiepmentnameValidity === 'invalid'">Please enter a valid Equipement Name!</p>
    </div>
    <div class="form-control" :class="{invalid: siteValidity === 'invalid'}">
        <label for="enteredSite" class="control-label" >Site:</label>
    <input type="text" v-model="enteredSite" placeholder="Site" @blur="validatesite">
    <p v-if="siteValidity === 'invalid'">Please enter a valid Site!</p>
    </div>
    <div class="form-control" :class="{invalid: contactnoValidity === 'invalid'}">
        <label for="enteredContractno" class="control-label" >Contract No:</label>
    <input type="text" v-model="enteredContractno" placeholder="Contract No" @blur="validatecontactno">
    <p v-if="contactnoValidity === 'invalid'">Please enter a valid Contract No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Built Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Built Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Built Date!</p>
    </div>
     <div class="form-control" :class="{invalid: installdwgValidity === 'invalid'}">
        <label for="enteredInstalldwg" class="control-label" >Install.DWG:</label>
    <input type="text" v-model="enteredInstalldwg" placeholder="Install DWG" @blur="validateinstalldwg">
    <p v-if="installdwgValidity === 'invalid'">Please enter a valid Install DWG!</p>
    </div>
     <div class="form-control" :class="{invalid: refurbnoValidity === 'invalid'}">
        <label for="enteredRefurbno" class="control-label" >Refurb No.:</label>
    <input type="text" v-model="enteredRefurbno" placeholder="Refurb No" @blur="validaterefurbno">
    <p v-if="refurbnoValidity === 'invalid'">Please enter a valid Refurb No!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Mass (kg):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Mass (kg)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Mass (kg)!</p>
    </div>
    <div class="form-control" :class="{invalid: runningspeedValidity === 'invalid'}">
        <label for="enteredRunningspeed" class="control-label" >Running Speed (RPM):</label>
    <input type="text" v-model="enteredRunningspeed" placeholder="Running Speed (RPM)" @blur="validaterunningspeed">
    <p v-if="runningspeedValidity === 'invalid'">Please enter a valid Running Speed (RPM)!</p>
    </div>
    <div class="form-control" :class="{invalid: motorValidity === 'invalid'}">
        <label for="enteredMotor" class="control-label" >Motor (kW, Hz, POLE):</label>
    <input type="text" v-model="enteredMotor" placeholder="Motor (kW, Hz, POLE)" @blur="validatemotor">
    <p v-if="motorValidity === 'invalid'">Please enter a valid Motor (kW, Hz, POLE)!</p>
    </div>
    <div class="form-control" :class="{invalid: exciterValidity === 'invalid'}">
        <label for="enteredExciter" class="control-label" >Exciters (QTY x Type):</label>
    <input type="text" v-model="enteredExciter" placeholder="Exciters (QTY x Type)" @blur="validateexciter">
    <p v-if="exciterValidity === 'invalid'">Please enter a valid Exciters (QTY x Type)!</p>
    </div>
    <div class="form-control" :class="{invalid: plugValidity === 'invalid'}">
        <label for="enteredPlug" class="control-label" >Plug Weights LH & RH:</label>
    <input type="text" v-model="enteredPlug" placeholder="Plug Weights LH & RH" @blur="validateplug">
    <p v-if="plugValidity === 'invalid'">Please enter a valid Plug Weights LH & RH!</p>
    </div>
    <div class="form-control" :class="{invalid: middleValidity === 'invalid'}">
        <label for="enteredMiddle" class="control-label" >Middle (If App):</label>
    <input type="text" v-model="enteredMiddle" placeholder="Middle (If App)" @blur="validatemiddle">
    <p v-if="middleValidity === 'invalid'">Please enter a valid Middle (If App)!</p>
    </div>
    <div class="form-control" :class="{invalid: springValidity === 'invalid'}">
        <label for="enteredSpring" class="control-label" >Springs (QTY x Type):</label>
    <input type="text" v-model="enteredSpring" placeholder="Springs (QTY x Type)" @blur="validatespring">
    <p v-if="springValidity === 'invalid'">Please enter a valid Springs (QTY x Type)!</p>
    </div>
    <div class="form-control" :class="{invalid: springposValidity === 'invalid'}">
        <label for="enteredSpringpos" class="control-label" >Spring Pos.(FE>DE):</label>
    <input type="text" v-model="enteredSpringpos" placeholder="Spring Pos.(FE>DE)" @blur="validatespringpos">
    <p v-if="springposValidity === 'invalid'">Please enter a valid Spring Pos.(FE>DE)!</p>
    </div>
    <drawing-selection-12
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    v-bind:equipment-name="enteredEquipmentname"
    v-bind:contract-number="enteredContractno"
    v-bind:spring-pos="enteredSpringpos"
    v-bind:spring="enteredSpring"
    v-bind:plug="enteredPlug"
    v-bind:exciter="enteredExciter"
    v-bind:motor="enteredMotor"
    v-bind:running-speed="enteredRunningspeed"
    v-bind:refurb-number="enteredRefurbno"
    v-bind:install-dwg="enteredInstalldwg"
    v-bind:site="enteredSite"
    v-bind:material-number="enteredMaterialno"
    v-bind:middle="enteredMiddle"
    ></drawing-selection-12>
    </div>
    <div v-else-if="enteredDrwaing === '000-100002-18'" >
    <br>
    <div class="form-control" :class="{invalid: ModelnoValidity === 'invalid'}">
        <label for="enteredModelno" class="control-label" >Transport Beam Material No:</label>
    <input type="text" v-model="enteredModelno" placeholder="Transport Beam Model No" size="4" @blur="validatemodelno">
    <p v-if="ModelnoValidity === 'invalid'">Please enter a valid Transport Beam Model No!</p>
    </div>
    <div class="form-control" :class="{invalid: serialnoValidity === 'invalid'}">
        <label for="enteredSerialno" class="control-label" >Transport Beam Serial No:</label>
    <input type="text" placeholder="Transport Beam Serial No" v-model.trim="enteredSerialno" @blur="validateserialno">
    <p v-if="serialnoValidity === 'invalid'">Please enter a valid Transport Beam Serial No!</p>
    </div>
    <div class="form-control" :class="{invalid: rebuildjobnoValidity === 'invalid'}">
        <label for="enteredRebuildjobno" class="control-label" >Job No:</label>
    <input type="text" v-model="enteredRebuildjobno" placeholder=" Job No" @blur="validaterebuildjobno">
    <p v-if="rebuildjobnoValidity === 'invalid'">Please enter a valid  Job No!</p>
    </div>
    <div class="form-control" :class="{invalid: builtdateValidity === 'invalid'}">
        <label for="enteredBuiltdate" class="control-label" >Manufacture Date:</label>
    <input type="date" v-model="enteredBuiltdate" placeholder="Manufacture Date" @blur="validatebuiltdate">
    <p v-if="builtdateValidity === 'invalid'">Please enter a valid Manufacture Date!</p>
    </div>
    <div class="form-control" :class="{invalid: tagnoValidity === 'invalid'}">
        <label for="enteredTagno" class="control-label" >M.R.C. (Per Transport Beam) (t):</label>
    <input type="text" v-model="enteredTagno" placeholder="M.R.C. (Per Transport Beam) (t)" @blur="validatetagno">
    <p v-if="tagnoValidity === 'invalid'">Please enter a valid M.R.C. (Per Transport Beam) (t)!</p>
    </div>
    <div class="form-control" :class="{invalid: equiepmentnameValidity === 'invalid'}">
        <label for="enteredEquipmentname" class="control-label" >Equipement Model:</label>
    <input type="text" v-model="enteredEquipmentname" placeholder="Equipement Model" @blur="validateequiepmentname">
    <p v-if="equiepmentnameValidity === 'invalid'">Please enter a valid Equipement Model!</p>
    </div>
    <div class="form-control" :class="{invalid: masskgValidity === 'invalid'}">
        <label for="enteredMasskg" class="control-label" >Minimum Trailer Width (m):</label>
    <input type="text" v-model="enteredMasskg" placeholder="Minimum Trailer Width (m)" size="4" @blur="validatemasskg">
    <p v-if="masskgValidity === 'invalid'">Please enter a valid Minimum Trailer Width (m)!</p>
    </div>
    
    <drawing-selection-18
    v-bind:name="enteredDrwaing"
    v-bind:serial-number="enteredSerialno"
    v-bind:qr-code="'https://qrcode.schenckprocess.com.au/'+enteredSerialno" 
    v-bind:model-number="enteredModelno"
    v-bind:tag-number="enteredTagno"
    v-bind:built-date="enteredBuiltdate"
    v-bind:mass-kg="enteredMasskg"
    v-bind:equipment-name="enteredEquipmentname"
    v-bind:rebuild-job="enteredRebuildjobno"
    ></drawing-selection-18>
    </div>
    </form>
</template>
<script>
import DrawingSelection02 from './DrawingSelection02.vue';
import DrawingSelection04 from './DrawingSelection04.vue';
import DrawingSelection06 from './DrawingSelection06.vue';
import DrawingSelection07 from './DrawingSelection07.vue';
import DrawingSelection09 from './DrawingSelection09.vue';
import DrawingSelection12 from './DrawingSelection12.vue';
import DrawingSelection18 from './DrawingSelection18.vue';


export default {
    components: { DrawingSelection02, DrawingSelection04, DrawingSelection06,DrawingSelection07,DrawingSelection09,DrawingSelection12,DrawingSelection18, },
    data() {
        return {
            enteredMachine: '',
            enteredDrwaing: '',
            enteredSerialno: '',
            enteredModelno:'',
            enteredTagno:'',
            enteredRebuildserialno:'',
            enteredBuiltdate:'',
            enteredMasskg:'',
            enteredEquipmentname:'',
            enteredContractno:'',
            enteredRebuilddate:'',
            enteredRebuildjobno:'',
            enteredRano:'',
            enteredBhpbio:'',
            enteredStockcode:'',
            enteredPurchaseorder:'',
            enteredMaterialno:'',
            enteredSite:'',
            enteredRefurbno:'',
            enteredInstalldwg:'',
            enteredRunningspeed:'',
            enteredMotor:'',
            enteredExciter:'',
            enteredPlug:'',
            enteredMiddle:'',
            enteredSpring:'',
            enteredSpringpos:'',
            rebuildjobnoValidity:'pending',
            ModelnoValidity: 'pending',
            serialnoValidity: 'pending',
            tagnoValidity: 'pending',
            equiepmentnameValidity: 'pending',
            contactnoValidity: 'pending',
            builtdateValidity: 'pending',
            masskgValidity: 'pending',
            rebuildserialnoValidity: 'pending',
            rebuiltdateValidity: 'pending',
            ranoValidity:'pending',
            bhpbioValidity:'pending',
            bhpstockcodeValidity:'pending',
            purchaseorderValidity:'pending',
            materialnoValidity:'pending',
            siteValidity:'pending',
            installdwgValidity:'pending',
            refurbnoValidity:'pending',
            runningspeedValidity:'pending',
            motorValidity:'pending',
            exciterValidity:'pending',
            plugValidity:'pending',
            middleValidity:'pending',
            springValidity:'pending',
            springposValidity:'pending',
        }
    },
    methods:{
        
        validatespringpos() {
        if (this.enteredSpringpos === '') {
            this.springposValidity = 'invalid';
        } else {
            this.springposValidity = 'valid';
        }
        },
        validatespring() {
        if (this.enteredSpring === '') {
            this.springValidity = 'invalid';
        } else {
            this.springValidity = 'valid';
        }
        },
        validatemiddle() {
        if (this.enteredMiddle === '') {
            this.middleValidity = 'invalid';
        } else {
            this.middleValidity = 'valid';
        }
        },
        validateplug() {
        if (this.enteredPlug === '') {
            this.plugValidity = 'invalid';
        } else {
            this.plugValidity = 'valid';
        }
        },
        validateexciter() {
        if (this.enteredExciter === '') {
            this.exciterValidity = 'invalid';
        } else {
            this.exciterValidity = 'valid';
        }
        },
        validatemotor() {
        if (this.enteredMotor === '') {
            this.motorValidity = 'invalid';
        } else {
            this.motorValidity = 'valid';
        }
        },
        validaterunningspeed() {
        if (this.enteredRunningspeed === '') {
            this.runningspeedValidity = 'invalid';
        } else {
            this.runningspeedValidity = 'valid';
        }
        },
        validateinstalldwg() {
        if (this.enteredInstalldwg === '') {
            this.installdwgValidity = 'invalid';
        } else {
            this.installdwgValidity = 'valid';
        }
        },
        validaterefurbno() {
        if (this.enteredRefurbno === '') {
            this.refurbnoValidity = 'invalid';
        } else {
            this.refurbnoValidity = 'valid';
        }
        },
        validatemodelno() {
        if (this.enteredModelno === '') {
            this.ModelnoValidity = 'invalid';
        } else {
            this.ModelnoValidity = 'valid';
        }
        },
        validatematerialno() {
        if (this.enteredMaterialno === '') {
            this.materialnoValidity = 'invalid';
        } else {
            this.materialnoValidity = 'valid';
        }
        },
        validatesite() {
        if (this.enteredSite === '') {
            this.siteValidity = 'invalid';
        } else {
            this.siteValidity = 'valid';
        }
        },
        validateserialno() {
        if (this.enteredSerialno === '') {
            this.serialnoValidity = 'invalid';
        } else {
            this.serialnoValidity = 'valid';
        }
        },
         validatetagno() {
        if (this.enteredTagno === '') {
            this.tagnoValidity = 'invalid';
        } else {
            this.tagnoValidity = 'valid';
        }
        },
         validateequiepmentname() {
        if (this.enteredEquipmentname === '') {
            this.equiepmentnameValidity = 'invalid';
        } else {
            this.equiepmentnameValidity = 'valid';
        }
        },
         validatecontactno() {
        if (this.enteredContractno === '') {
            this.contactnoValidity = 'invalid';
        } else {
            this.contactnoValidity = 'valid';
        }
        },
         validatebuiltdate() {
        if (this.enteredBuiltdate === '') {
            this.builtdateValidity = 'invalid';
        } else {
            this.builtdateValidity = 'valid';
        }
        },
         validatemasskg() {
        if (this.enteredMasskg === '') {
            this.masskgValidity = 'invalid';
        } else {
            this.masskgValidity = 'valid';
        }
        },
        validaterebuiltdate() {
        if (this.enteredRebuilddate === '') {
            this.rebuiltdateValidity = 'invalid';
        } else {
            this.rebuiltdateValidity = 'valid';
        }
        },
        validaterebuildjobno() {
        if (this.enteredRebuildjobno === '') {
            this.rebuildjobnoValidity = 'invalid';
        } else {
            this.rebuildjobnoValidity = 'valid';
        }
        },
         validaterano() {
        if (this.enteredRano === '') {
            this.ranoValidity = 'invalid';
        } else {
            this.ranoValidity = 'valid';
        }
        },
        validatebhpbio() {
        if (this.enteredBhpbio === '') {
            this.bhpbioValidity = 'invalid';
        } else {
            this.bhpbioValidity = 'valid';
        }
        },
        validatebhpstockcode() {
        if (this.enteredStockcode === '') {
            this.bhpstockcodeValidity = 'invalid';
        } else {
            this.bhpstockcodeValidity = 'valid';
        }
        },
        validatepurchaseorder() {
        if (this.enteredPurchaseorder === '') {
            this.purchaseorderValidity = 'invalid';
        } else {
            this.purchaseorderValidity = 'valid';
        }
        },
    },
};
</script>
<style scoped>

.form-control {
  margin: 0.5rem 0;
}

.form-control.invalid input {
  border-color: red;
}




</style>
